@font-face {
    font-family: "Circular Std Black";
    src: url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.eot");
    src: url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.woff2") format("woff2"), url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.woff") format("woff"), url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.ttf") format("truetype"), url("./assets/fonts/f1a22f6f15d272c7aa56da8b2c91f5e5.svg#Circular Std Black") format("svg");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --blue: #4d141d;
    --yellow: #e7b874;
    --bold-font: "Circular Std Black";
    --normal-font: "Kumbh Sans";
    --light-blue: #714f54;
}

body {
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 400 !important;
}

.banner {
    background-color: var(--blue);
    width: 100% !important;
    height: 99vh;
}

.banner-bg {
    background-image: url('./assets/banner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contact-icon {
    width: 200px;
}

.section-sm {
    background: var(--yellow);
    width: 74%;
    margin: auto;
    height: auto;
    margin-top: -50px;
    padding-bottom: 30px;
}

.banner>.content {
    width: 50%;
    margin-left: 225px;
    margin-top: 50px;
}

.header-logo {
    max-width: 160px;
}

.header {
    padding: 50px;
    display: flex;
    justify-content: space-between;
}

.title-text {
    font-size: 3em;
    font-family: "Circular Std Black";
    color: white;
    font-weight: 200;
}

.banner>.dash {
    width: 200px;
    margin-left: 225px;
    margin-top: 50px;
    height: 10px;
    background-color: var(--yellow);
}

.banner>.explore {
    width: 200px;
    margin-left: 225px;
    margin-top: 80px;
    color: white;
    font-size: 1.2em;
    transition: 0.8s;
}

.mt-b {
    margin-bottom: 30px;
}

.explore:hover {
    font-size: 1.3em;
    cursor: pointer;
}

.m-wrapper {
    display: flex;
    justify-content: flex-end;
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 10 !important;
}

.menu-icon {
    z-index: 10 !important;
    background-color: white;
    padding: 15px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 70px;
}

.menu-bar {
    font-size: 2em;
}

.ma-n {
    margin-top: -20px;
}

.ma-na {
    font-size: 1em !important;
}

.close-icon {
    font-size: 2em;
}

.menu-bar:hover,
.close-icon {
    cursor: pointer;
}

.navbar {
    width: 100%;
    height: 100vh !important;
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
}

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    color: white;
    margin: auto;
    position: fixed;
}

.nav-section1 {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-section2 {
    width: 60%;
}

.link:hover {
    color: var(--light-blue);
}

.nav-section1-content,
a {
    color: white;
    font-family: var(--bold-font);
    font-size: 1.7em;
    text-decoration: none;
}

.nav-section1-content>div {
    margin-bottom: 20px;
}

.compass-icon {
    color: #a96d49 !important;
    font-size: 3em;
}

.nav-section2 {
    margin-top: 100px;
    font-family: var(--normal-font);
    font-weight: 200;
}

.sub-section {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
}

.sub-section,
p {
    margin-top: 10px;
}

.sub {
    color: var(--light-blue);
    letter-spacing: 1px;
}

.sub-title {
    font-size: 1.3em;
    font-family: var(--bold-font);
    margin-bottom: 20px;
    color: var(--light-blue);
}

.ml-4 {
    margin-left: 100px;
}

.mt-4 {
    margin-top: 40px;
}

.logo-nav {
    max-width: 120px;
    margin-left: 150px;
    margin-top: 20px;
}

.sm-content {
    width: 80%;
    margin: auto;
    padding-top: 150px;
    text-align: left;
    color: white;
    line-height: 25px;
}

.title1 {
    color: var(--blue);
    font-family: var(--bold-font);
    font-size: 4em;
    margin-top: 30px;
    margin-bottom: 30px;
}

.text {
    font-size: 20px;
}

.button {
    border: 3px white solid;
    padding: 5px 20px;
    width: max-content;
    border-radius: 8px;
    transition: 0.6s;
}

.button:hover {
    background: white;
    cursor: pointer;
    color: var(--yellow);
}

.center {
    display: flex;
    justify-content: center;
    text-align: center;
}

.section-bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--yellow);
}

.part1 {
    width: 50%;
}

.part2 {
    width: 50%;
}

.image-banner {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.part1 {
    display: flex;
    justify-content: center;
    margin: auto;
}

.our-methodology {
    width: 80%;
}

.title-bold {
    font-size: 4em;
    font-family: var(--bold-font);
    color: var(--blue);
}

.description {
    font-size: 1.2em;
    color: rgb(116, 112, 112);
    line-height: 26px;
}

.empty-icon {
    width: 150px;
}

.section-mentors {
    width: 80%;
    margin: auto;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.flex-it {
    display: flex;
    flex-direction: row;
}

.card-list {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 40px;
}

.mentors-list {
    width: 35%;
}

.mentor-content {
    width: 60%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: var(--bold-font);
    color: var(--yellow);
    font-size: 5em;
}

.m-content {
    height: 85%;
    margin-left: 10px;
}

.mentor-image {
    width: 100%;
    height: 100%;
}

.folder-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
}

.f-item {
    margin: 20px;
    text-align: center;
}

.f-banner {
    width: 450px;
    height: 250px;
}

.med-1 {
    font-size: 0.5em;
}

.con-icon {
    background-color: var(--blue);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.c-icon {
    color: white;
    background-color: var(--blue);
    padding: 18px;
    translate: 1s;
    font-size: 1.2em;
    border-radius: 50%;
}

.c-icon:hover {
    background-color: var(--light-blue);
    cursor: pointer;
}

.prev-next {
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.prev-next>div {
    margin-top: 20px;
    margin-right: 20px;
}

.white {
    color: white;
}

.a-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.a-card {
    max-width: 300px;
    max-height: 600px;
    text-align: center;
    margin: 30px;
}

.a-card {
    margin-top: 30px;
}

.s-icon {
    font-size: 1.5em;
    margin-right: 10px;
}

.markdown p>img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.markdown p {
    margin: 2=10px 0;
    margin-bottom: 30px;
}

.markdown img {
    max-width: 80%;
}

.a-image {
    width: 250px;
    height: 250px;
}

.semi-blod {
    text-align: center;
    color: #26272b;
    font-size: 1em;
}

.section-achievements {
    margin-top: 100px;
    width: 80%;
    margin: auto;
}

.med {
    font-size: 2em;
}

.f-part {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding-top: 70px;
}

.footer-left {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin-right: 100px;
}

.footer-right {
    margin-left: 100px;
    width: 50%;
}

.markdown>h1,
.markdown>h2,
.markdown>h3 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: var(--bold-font);
    color: var(--blue);
}

.markdown>ul,
.markdown>ol {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

strong {
    color: var(--blue) !important;
}

.footer {
    width: 100%;
    min-height: 400px;
    background-color: var(--blue);
    color: white;
    margin-top: 100px;
}

.logo-footer {
    margin-top: 50px;
    max-width: 150px;
    margin-bottom: 30px;
}

.partner-image {
    width: 200px;
    height: 200px;
    margin: 90px;
    margin-top: -20px;
}

.link-a {
    font-family: var(--normal-font);
    font-weight: 100;
    font-size: 1em;
}

.partner-list::-webkit-scrollbar {
    display: none;
}

.author-image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.m-a {
    margin-top: 8px;
    margin-right: -6px;
    font-size: 1.1em;
}

.partner-list {
    display: flex;
    overflow-x: auto;
    justify-content: center;
}

.flex-c {
    margin-top: 30px;
    margin-left: 20px !important;
}

.triangle-left {
    width: 0;
    height: 0;
    border-top: 600px solid transparent;
    border-right: 500px solid var(--yellow);
    position: absolute;
    right: 0;
    border-bottom: 25px solid transparent;
}

.banner-2 {
    height: 50vh;
}

.productions {
    width: 80%;
    margin: auto;
    margin-top: 40px;
}

.production-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.vm {
    margin-top: 10px;
    margin-bottom: 8px;
}

.e-card {
    padding: 20px;
    background-color: rgb(250, 250, 250);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
    margin: 20px;
    width: 400px;
    margin-top: 80px;
    min-height: 400px !important;
    max-height: 800px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-image {
    text-align: center;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-title {
    font-size: 1em;
    margin-top: 10px;
    color: #271e41;
    font-family: var(--fb);
    margin-bottom: 15px;
}

.mm {
    margin: 250px;
}

.card-details {
    color: #8d91a3;
    font-size: 0.8em;
    margin-top: 10px !important;
}

.view-more-btn {
    text-align: center;
    padding: 10px;
    background-color: var(--blue);
    border-radius: 8px;
    color: white;
    margin-top: 40px;
    transition: 0.5s;
}

.view-more-btn>a {
    color: white !important;
    font-family: var(--fm);
}

.view-more-btn:hover {
    cursor: pointer;
    background-color: var(--light-blue);
}

.flex-c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
}

.p-card {
    width: 400px;
    background: var(--yellow);
    padding: 20px;
    color: white;
    max-height: 600px;
    min-height: 600px;
    margin: 20px;
}

.p-card-title {
    font-size: 1.2em;
    color: var(--blue);
    font-family: var(--bold-font);
    margin-bottom: 10px;
}

.button-2 {
    margin-top: 20px;
    border: white 2px solid;
    border-radius: 5px;
    padding: 5px 7px;
}

.button-2:hover {
    color: var(--yellow);
}

.v-b {
    font-size: 14px;
    font-family: var(--normal-font);
}

.button-2>p {
    font-size: 0.9em;
}

.container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 100px 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;
}

.gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery-item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gallery-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}


/* .gallery-item:hover .image img {
    transform: scale(1.5);
} */

.gallery-item .text {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 25px;
    pointer-events: none;
    z-index: 4;
    transition: 0.3s ease-in-out;
}

.gallery-item:hover .text {
    opacity: 1;
    animation: move-down 0.3s linear;
    padding: 1em;
    width: 100%;
}

.w-1 {
    grid-column: span 1;
}

.w-2 {
    grid-column: span 2;
}

.w-3 {
    grid-column: span 3;
}

.w-4 {
    grid-column: span 4;
}

.w-5 {
    grid-column: span 5;
}

.w-6 {
    grid-column: span 6;
}

.h-1 {
    grid-row: span 1;
}

.h-2 {
    grid-row: span 2;
}

.h-3 {
    grid-row: span 3;
}

.h-4 {
    grid-row: span 4;
}

.h-5 {
    grid-row: span 5;
}

.h-6 {
    grid-row: span 6;
}

.d-container {
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

@media screen and (max-width: 500px) {
    .container {
        grid-template-columns: repeat(1, 1fr);
    }
    .w-1,
    .w-2,
    .w-3,
    .w-4,
    .w-5,
    .w-6 {
        grid-column: span 1;
    }
}

@keyframes move-down {
    0% {
        top: 10%;
    }
    50% {
        top: 35%;
    }
    100% {
        top: 50%;
    }
}

.button-dark {
    margin-top: 50px;
    padding: 5px 15px;
    background-color: var(--blue);
    color: white;
    padding-bottom: 10px;
}

.button-dark:hover {
    background-color: var(--light-blue);
    cursor: pointer;
}

.explore-link {
    font-family: var(--normal-font) !important;
    font-size: 1em;
}

.blog-container {
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.banner-img {
    width: 100%;
    height: 50vh;
    opacity: 0.9;
    background-color: var(--blue);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.banner-b-content {
    width: 70%;
    margin: auto;
    text-align: center;
}

.banner-title-b {
    font-size: 5em;
}

.banner-img:hover {
    cursor: pointer;
    opacity: 0.4;
}

.blog-list {
    width: 60%;
    margin: auto;
    margin-top: 20px;
}

.blog-item-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.d-img {
    width: 100%;
    text-align: center;
}

.lazy-load-image-loaded {
    height: auto !important;
}

.d-image {
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
}

.p-card-img {
    width: 360px;
}

.p-card-i {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.bar {
    width: 100%;
    margin-top: 10px;
    background-color: var(--yellow);
    color: white;
    font-family: var(--bold-font);
}

.bar-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.info {
    font-size: 1.5em;
}

.info-detail {
    font-size: 1.2em;
    font-family: var(--normal-font);
}

.bar-item {
    margin: 15px 50px;
}

.blog-item-image-holder {
    width: 30%;
}

.blog-item-content {
    width: 60%;
    margin-left: 20px;
}

.d-content {
    margin-top: 30px;
}

.b-i-tittle {
    font-size: 1.3em !important;
    font-family: var(--bold-font) !important;
    color: var(--blue) !important;
}

.blog-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.banner-blog-img {
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video {
    margin: 50px;
    text-align: center;
}

.mb-10 {
    margin-bottom: 30px;
}

.register {
    padding: 20px;
    border: none;
    background-color: var(--blue);
    color: white;
    font-weight: 600;
    font-family: var(--normal-font);
    font-size: 16px;
}

.register:hover {
    background: var(--light-blue);
    cursor: pointer;
}

.move {
    border-top: 400px solid transparent;
    border-right: 500px solid var(--yellow);
}

.m {
    font-size: 2.5em;
    margin-bottom: 40px;
}

.message {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
}

input {
    width: 100%;
    border: none;
    border: 1px solid rgb(161, 156, 156);
    height: 40px;
    padding: 10px;
    margin: 10px 0px;
}

textarea {
    padding: 10px;
    font-size: 16px;
}

.submit-btn {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border: none;
    margin-top: 20px;
}

.submit-btn:hover {
    background-color: #a96d49;
    cursor: pointer;
}

.button-2:hover {
    background-color: #e7ac59;
}

.c2 {
    color: var(--blue);
}

.small {
    font-size: 2em;
}

.markdown table {
    width: 100% !important;
    margin-bottom: 50px;
}

@media (max-width: 800px) {
    .markdown img {
        max-width: 100%;
    }
    .ma-n {
        margin-bottom: 20px;
    }
    .f-banner {
        width: 350px;
        height: 200px;
    }
    .folder-list {
        justify-content: center;
    }
    .bar {
        text-align: center;
    }
    .bar-items {
        justify-content: center;
        flex-direction: column;
    }
    .contact-icon {
        width: 120px;
    }
    .production-list {
        width: 98%;
    }
    .p-card {
        width: 90%;
    }
    .partner-image {
        width: 100px;
        height: 100px;
        margin: 20px;
    }
    .blog-container {
        width: 98%;
        margin-top: 2px;
    }
    .blog-item-image-holder {
        width: 100%;
    }
    .blog-list {
        width: 90%;
    }
    .banner-title-b {
        font-size: 2em;
    }
    .b-i-tittle {
        font-size: 1.5em !important;
    }
    .blog-item-content {
        width: 95%;
        margin: 0;
        margin: auto;
        font-size: 0.9em;
    }
    .blog-item {
        flex-direction: column;
    }
    .productions {
        margin-top: 30px;
        width: 100%;
    }
    .triangle-left {
        border-top: 100px solid transparent;
        border-right: 50px solid var(--yellow);
    }
    .f-part {
        flex-direction: column;
        width: 60%;
    }
    .footer-left {
        justify-content: start;
        margin: 0;
        width: 100%;
    }
    .footer-right {
        width: 100%;
        margin: 0;
    }
    .section-mentors {
        width: 100%;
    }
    .mentors-list {
        width: 100%;
        text-align: center;
    }
    .mentor-content {
        width: 100%;
        padding-bottom: 0px;
    }
    .title {
        font-size: 2.5em;
    }
    .prev-next {
        justify-content: start;
    }
    .mentor-content {
        padding: 0;
    }
    .m-content {
        width: 100%;
    }
    .card-list {
        width: 100%;
        margin: auto;
        margin-top: 30px;
    }
    .title-bold {
        font-size: 3em;
    }
    .our-methodology {
        width: 90%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .description {
        font-size: 1.1em;
    }
    .card-list {
        flex-direction: column;
    }
    .section-bar {
        flex-direction: column-reverse;
    }
    .part1 {
        width: 100%;
    }
    .part2 {
        width: 100%;
    }
    .banner>.content {
        width: 80%;
        margin: auto;
    }
    textarea {
        padding: 10px;
    }
    .title-text {
        font-size: 2em;
    }
    .banner>.explore {
        margin-top: 20px;
        margin-left: 40px;
    }
    .banner>.dash {
        margin-left: 40px;
    }
    .section-sm {
        width: 100%;
    }
    .no-mobile {
        display: none;
    }
    .nav-section1 {
        width: 100%;
        margin: 0 !important;
    }
    .link {
        font-size: 1em;
    }
    .compass-icon {
        font-size: 2em;
    }
    .text {
        font-size: 1.1em;
        line-height: 20px;
    }
    .m {
        font-size: 2em;
        margin-bottom: 30px;
    }
    .title1 {
        font-size: 2.2em;
    }
    .title-text {
        font-size: 1.5em;
    }
    .sm-content {
        padding-top: 50px;
    }
    .description,
    .text,
    .p-content {
        font-size: 16px;
    }
    .p-content {
        margin-top: 10px;
    }
    .title-bold {
        font-size: 2em;
    }
    #widget2 {
        width: 100%;
        height: 200px;
    }
    .d-container {
        width: 92%;
    }
    .small {
        font-size: 1em;
    }
}

.sub {
    font-size: 1.5em;
}


/* loader */

.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--blue);
    animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
}

@keyframes lds-grid {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}